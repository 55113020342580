let worker;
let textDiv;
let canvasDiv;
let turtleElem;
let ctx;
const resolvers = [];
export function initialize(options) {
    textDiv = options.textDiv;
    canvasDiv = options.canvasDiv;
    turtleElem = options.turtleElem;
    const mode = options.mode ?? 'webgl';
    const fps = options.fps ?? 30;
    ctx = canvasDiv.getContext("2d", {
        alpha: false,
    });
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvasDiv.width, canvasDiv.height);
    if (mode === '2d') {
        worker = new Worker(new URL('./worker-2d.ts', import.meta.url));
    }
    else if (mode === 'webgl') {
        worker = new Worker(new URL('./worker-webgl.ts', import.meta.url));
    }
    else {
        throw new Error();
    }
    worker.onmessage = ({ data }) => {
        switch (data.type) {
            case 'print':
                textDiv.appendChild(document.createTextNode(data.text));
                break;
            case 'clearText':
                textDiv.replaceChildren();
                break;
            case 'draw':
                if (data.bitmap instanceof ImageBitmap) {
                    ctx.drawImage(data.bitmap, 0, 0);
                    data.bitmap.close();
                }
                if (data.bitmap instanceof ImageData) {
                    ctx.putImageData(data.bitmap, 0, 0);
                }
                if (data.turtle) {
                    turtleElem.style.transform =
                        `scaleY(-1) translate(${data.turtle.x}px, ${data.turtle.y}px) rotate(${-data.turtle.heading}deg)`;
                    turtleElem.style.visibility = 'visible';
                }
                else {
                    turtleElem.style.visibility = 'hidden';
                }
                break;
            case "finished":
                const resolve = resolvers.shift();
                resolve();
                break;
        }
    };
    worker.postMessage({
        type: 'init',
        width: canvasDiv.width,
        height: canvasDiv.height,
        fps,
    });
}
export function run(command) {
    if (!worker) {
        throw new Error();
    }
    const { promise, resolve } = Promise.withResolvers();
    resolvers.push(resolve);
    worker.postMessage({
        type: 'run',
        command,
    });
    return promise;
}
